// portfolio section
.portfolio
{
    .filters
    {
        margin-bottom: 1.5rem;

        ul
        {
            text-align: right;
            list-style: none;
            display: flex;
            justify-content: flex-end;
            flex-wrap: wrap;
            
            li
            {
                margin-right: 0.5rem;
                padding: 0.5rem;
                font-size: 12px;
                font-weight: 600;

                &:hover
                {
                    color: var(--ternary);
                    cursor: pointer;
                }
            }

            .active
            {
                color: var(--ternary);
            }

            @include break2
            {
                text-align: left;
                justify-content: flex-start;
            }
        }
    }
    .card
    {
        padding: 0;
        position: relative;
        margin: auto;
        background: var(--primary);
        max-width: 350px;

        img
        {
            max-width: 350px;
            max-height: 350px;
            width: 100%;
            min-width: 100%;
            height: auto;
            display: block;
            transition: opacity ease-in-out 250ms;
            opacity: 1;
        }

        .card-text
        {
            position: absolute;
            left:50%;
            top:50%;
            color: var(--secondary);
            opacity: 0;
            transform: translate(-50%, -50%);
            transition: opacity ease-in-out 250ms;
            text-align: center;

            h3
            {
                font-size: 28px;
                margin-bottom: 1rem;
            }
            p
            {
                font-size: 22px;
            }
        }

        &::before,&::after
        {
            content: '';
            position: absolute;
            top:1.25em;
            right:1.25em;
            left:1.25em;
            bottom:1.25em;
        }
        &::before
        {
            border-top: 1px solid var(--secondary);
            border-bottom: 1px solid var(--secondary);
            transform: scale(0,1);
            transition: transform ease-out 250ms;
        }
        &::after
        {
            border-left: 1px solid var(--secondary);
            border-right: 1px solid var(--secondary);
            transform: scale(1,0);
            transition: transform ease-out 250ms;
        }
        @include break2
        {
            transform: none;

            .card-text
            {
                opacity: 1;
            }
            img
            {
                opacity: 0.1;
            }
            a
            {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                z-index: 1000;
            }
            &::before
            {
                transform: scale(1.05,1);
            }
            &::after
            {
                transform: scale(1,1.05);
            }
        }
        &:hover
        {
            transform: none;

            .card-text
            {
                opacity: 1;
            }
            img
            {
                opacity: 0.1;
            }
            a
            {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                z-index: 1000;
            }
            &::before
            {
                transform: scale(1.05,1);
            }
            &::after
            {
                transform: scale(1,1.05);
            }
        }
    }

    @include break1
    {
        padding: 0 1rem;
    }
    @include break2
    {
        padding: 0 2rem;
    }
    @include break3
    {
        padding: 0 1rem;
    }
}

// cards for blogs
.blog-card
{
    margin: 3rem 0;

    .card-link
    {
        position: relative;
        display: block;
        color: inherit;
        text-decoration: none;
        transition: all 0.3s ease-in-out 0s;

        &:hover
        {
            .post-title
            {
                color: var(--ternary);
            }
        }
    }

    .card-body
    {
        display: flex;
        flex-direction: row;
        background: var(--primary);
        box-shadow: 0.5px 0.5px 3px var(--secondary-grey);
        border-radius: 1rem;
        overflow: hidden;

        .post-image 
        {
            display: block;
            width: 100%;
            object-fit: cover;
        }
        
        .article-details 
        {
            padding: 1.5rem;
        }
        
        .post-category 
        {
            display: inline-block;
            color: var(--ternary);
            text-transform: uppercase;
            font-size: 17px;
            font-weight: 700;
            margin: 0 0 1rem 0;
            padding: 0 0 0.25rem 0;
            border-bottom: 2px solid var(--secondary-grey);
        }
        
        .post-title 
        {
            font-size: 22px;
            font-weight: 700;
            margin-bottom: 0.5rem;
            text-transform: capitalize;
        }
        
        .post-author 
        {
            font-size: 14px;
            font-weight: 600;
            margin-top: 1rem;
            padding-top: 1rem;
            color: var(--ternary);
            border-top: 2px solid var(--secondary-grey);
        }

        @include break2
        {
            flex-wrap: wrap;
        }
    }
}
