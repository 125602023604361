//dark mode and light mode color settings
html, html[data-theme='light']
{
    --primary: #f7f7f7;
    --secondary: #021017;
    --ternary: #D3165A;
    --primary-grey: #eee;
    --secondary-grey: #555;
    --code-bg: #f9f9f9;
    --code-color: #212529;
    --code-linenos-bg: #dfefff;
    --code-linenos-color: #5499de;
    --bulb-content: "\f185";
    --bg:url(../images/landing_light.svg);
    --icon:url(../images/logo_primary.svg);
    --pattern:url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAAE0lEQVQYV2NkwAIYqSx4WSyqAQAGZgHJ5SfX3gAAAABJRU5ErkJggg==);
    @import "highlight_light";
}

html[data-theme='dark']
{
    --primary: #021017;
    --secondary: #f1f1f1;
    --ternary: #6FECFD;
    --primary-grey: #031b27;
    --secondary-grey: #eee;
    --code-bg: #1b1d1e;
    --code-color: #f8f8f2;
    --code-linenos-bg: #021017;
    --code-linenos-color: #88b3de;
    --bulb-content: "\f0eb";
    --bg:url(../images/landing_dark.svg);
    --icon:url(../images/logo_dark.svg);
    --pattern:url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAAG0lEQVQYV2PMe/3feJIo41kGJMCIzIGxKRQEAJXfBAbFZIeGAAAAAElFTkSuQmCC);
    @import "highlight_dark";

    img 
    {
        filter: brightness(.8) contrast(1.2);
    }
}

html .transition,html.transition *,html.transition *:before,html.transition *:after
{
    transition: all 500ms ease !important;
    transition-delay: 0 !important;
}