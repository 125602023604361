// make text to center
.center-text
{
    text-align: center !important;
}
// margin-top 5rem
.mt-5
{
    margin-top: 5rem !important;
}
// margin-top 3rem
.mt-3
{
    margin-top: 3rem !important;
}
// margin-bottom 5rem
.mb-5
{
    margin-bottom: 5rem;
}
//margin-bottom 3rem
.mb-3
{
    margin-bottom: 3rem;
}
// alert box
.alert
{
    text-align: center;
    border-radius: 10px;
    min-height: 50px;
    line-height: 50px;
    color: var(--ternary);
    border: var(--ternary) 1px solid;
    animation: fade-down 2s ease;
    margin: 3rem;
}